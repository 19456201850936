import React from 'react';

const Work = ({ work }) => {
  return (
    <div className='portfolio__items-item'>
      <div className='portfolio__items-item__image'>
        <img src={work.image} alt={work.title} />
      </div>
      <div className='portfolio__items-item__overlay'>
        <div className='portfolio__items-item__overlay-content'>
          <h3 className='portfolio__items-item__overlay-title'>{work.title}</h3>
          <p className='portfolio__items-item__overlay-category'>
            {work.category}
          </p>
          <a
            href={work.link}
            className='portfolio__items-item__overlay-link'
            target='_blank'
            rel='noreferrer'
            aria-label={work.title}
          >
            <i className='icon-link'></i>
          </a>
          <a
            href={work.github}
            className='portfolio__items-item__overlay-github'
            target='_blank'
            rel='noreferrer'
            aria-label={work.title}
          >
            <i className='icon-social-github'></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Work;
