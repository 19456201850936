import React from 'react';

const ServiceItem = ({ service }) => {
  return (
    <div
      className='services-item'
      style={{ backgroundColor: service.bgColor, color: service.color }}
    >
      <img src={service.icon} alt={service.title} />
      <h3 style={{ color: service.color }}>{service.title}</h3>
      <p>{service.content}</p>
    </div>
  );
};

export default ServiceItem;
