import './index.scss';
import { Title } from 'components';
import { BlogPostItem } from './components';
import { posts } from 'utils/@fake_db';

export default function Blog() {
  return (
    <section id='blog' className='blog'>
      <Title title='Latest Posts' />
      <div className='blog__container'>
        {posts.map(post => (
          <BlogPostItem key={post.id} item={post} />
        ))}
      </div>
    </section>
  );
}
