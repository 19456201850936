import { useState, useEffect, useCallback } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import './index.scss';
import { works } from 'utils/@fake_db';
import { Title } from 'components';
import { CategoryButton, Work } from './components';

export default function Portfolio() {
  const [animationParent] = useAutoAnimate();
  const categories = ['All', 'React', 'NodeJS', 'Laravel', 'Remix Run'];

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredWorks, setFilteredWorks] = useState(works);

  const handleWorksForCategory = useCallback(() => {
    if (selectedCategory === 'All') {
      setFilteredWorks(works);
    } else {
      const worksForCategory = works.filter(
        work => work.category.toLowerCase() === selectedCategory.toLowerCase()
      );
      setFilteredWorks(worksForCategory);
    }
  }, [selectedCategory, works]); // deepscan-disable-line

  useEffect(() => {
    handleWorksForCategory();
  }, [selectedCategory]);

  return (
    <section id='portfolio' className='portfolio'>
      <Title title='Portfolio' />
      <div className='portfolio__container'>
        <div className='portfolio__filter'>
          {categories.map((category, index) => (
            <CategoryButton
              key={index}
              name={category}
              category={selectedCategory}
              setCategory={setSelectedCategory}
            />
          ))}
        </div>
        <div className='portfolio__items' ref={animationParent}>
          {filteredWorks.map(work => (
            <Work key={work.id} work={work} />
          ))}
        </div>
      </div>
    </section>
  );
}
