import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const Form = () => {
  const form = useRef();

  const notifySuccess = () => toast.success('Message sent successfully! 🦄');
  const notifyError = () => toast.error('Message not sent! 😢');

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        result => {
          if (result.status === 200) {
            notifySuccess();
            form.current.reset();
          }
        },
        error => {
          notifyError();
        }
      );
  };

  return (
    <form
      className='contact__content__form'
      autoComplete='off'
      ref={form}
      onSubmit={sendEmail}
    >
      <div className='form__group'>
        <input
          type='text'
          placeholder='Insert your name'
          name='username'
          required
        />
        <input
          type='email'
          placeholder='Insert your email'
          name='email'
          required
          pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
        />
      </div>
      <div className='form__group'>
        <input
          type='subject'
          placeholder='Insert your subject'
          name='subject'
          required
        />
      </div>
      <div className='form__group'>
        <textarea
          name='message'
          id='message'
          cols='30'
          rows='10'
          placeholder='Insert your message'
          required
        ></textarea>
      </div>
      <div className='form__group'>
        <button type='submit'>Send Message</button>
      </div>
    </form>
  );
};

export default Form;
