import React from 'react';

const PlanCard = ({ plan }) => {
  return (
    <div
      className={`pricing__card ${plan.active ? 'active' : ''}`}
      key={plan.id}
    >
      {plan.active && <div className='pricing__card__active'>Popular</div>}
      <div className='pricing__image'>
        <img src={plan.image} alt={plan.name} />
      </div>
      <h3 className='pricing__card__title'>{plan.name}</h3>
      <p className='pricing__card__description'>{plan.description}</p>
      <ul className='pricing__card__list'>
        {plan.features.map((feature, index) => (
          <li key={index} className='pricing__card__list__item'>
            <i className='icon-pin'></i>
            <span style={{marginLeft: "6px"}}>{feature}</span>
          </li>
        ))}
      </ul>
      <h4 className='pricing__card__price'>${plan.price}</h4>
      <button className='pricing__card__button'>Choose Plan</button>
    </div>
  );
};

export default PlanCard;
