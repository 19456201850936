import './index.scss';
import { Title } from 'components';
import { ServiceItem } from './components';
import { services } from 'utils/@fake_db';

export default function Services() {
  return (
    <section className='services' id='services'>
      <Title title='Services' />
      <div className='services-container'>
        {services.map(service => (
          <ServiceItem service={service} key={service.id} />
        ))}
      </div>
    </section>
  );
}
