import React from 'react';
import { stats } from 'utils/@fake_db';

const Stats = () => {
  return (
    <div className='about-header__stats-right'>
      {stats.map(stat => (
        <div key={stat.id} className='about-header__stats-right-item'>
          <h4>{stat.title}</h4>
          <div className='progress'>
            <div
              className='progress-bar'
              style={{ width: stat.level, backgroundColor: stat.color }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stats;
