import React from 'react';

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className='testimonials__card'>
      <div className='testimonials__image'>
        <img src={testimonial.image} alt={testimonial.name} />
      </div>
      <h3 className='testimonials__name'>{testimonial.name}</h3>
      <h4 className='testimonials__title'>{testimonial.title}</h4>
      <div className='testimonials__content'>
        <p className='testimonials__description'>{testimonial.description}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
