import React from 'react';

const BlogPostItem = ({ item }) => {
  return (
    <a href={item.link} target='_blank' className='blog-item' rel='noreferrer' aria-label={item.title}>
      <div className='blog-item__image'>
        <img src={item.image} alt={item.title} />
      </div>
      <div className='blog-item__content'>
        <h3 className='blog-item__title'>{item.title}</h3>
        <p className='blog-item__description'>{item.description}</p>
      </div>
      <div className='blog-item__footer'>
        <span className='blog-item__date'>{item.date}</span>
        <span className='blog-item__author'>{item.author}</span>
      </div>
      <div className='blog-item__category'>{item.category}</div>
    </a>
  );
};

export default BlogPostItem;
