import './index.scss';
import { ME } from 'assets';
import HeaderSocials from './components/HeaderSocials';
import Shapes from './components/Shapes';

export default function Home() {
  return (
    <section className='home' id='home'>
      <div className='home__content'>
        <div className='home__intro'>
          <img src={ME} alt='Me' className='home__img' />
          <h1 className='home__title'>Harun Doğdu</h1>
          <p className='home__subtitle'>I'm a Full Stack Developer</p>

          <HeaderSocials />

          <a className='btn' href='#contact' aria-label='Hire Me Button'>
            Hire Me
          </a>
        </div>
        <a
          href='#about'
          className='home__scroll'
          aria-label='Scroll Down Button'
        >
          <div className='scroll__title'>Scroll Down</div>
          <div className='scroll__area'>
            <div className='scroll__icon' />
          </div>
        </a>
      </div>

      <Shapes />
    </section>
  );
}
