import React from 'react';
import { footerItems } from 'utils/@fake_db';

const Footer = () => {
  return (
    <div className='about-footer'>
      {footerItems.map(item => (
        <div key={item.id} className='about-footer-item'>
          <div className='icon'>{item.icon}</div>
          <div className='content'>
            <h5>{item.title}</h5>
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Footer;
