import './index.scss';
import { ME } from 'assets';
import { Footer, Stats } from './components';
import { Title } from 'components';

export default function About() {
  return (
    <section className='about' id='about'>
      <Title title='About Me' />
      <div className='about-content'>
        <div className='about-header'>
          <div className='about-header__image'>
            <img src={ME} alt='My Avatar' />
          </div>
          <div className='about-header__stats'>
            <div className='about-header__stats-left'>
              <p className='about-header__stats-left-text'>
                I am a software developer with more than 5 years of experience
                in web and mobile development, who loves to read and research. I
                am 24 years old and I live in Ankara. I graduated from Karadeniz
                Technical University, Software Engineering. In my spare time, I
                blog on medium and give various trainings.
              </p>
              <a
                href='https://drive.google.com/file/d/14ogqTB0L399tmPBkmLxzg9Of467TP1Ld/view?usp=sharing'
                target='_blank'
                className='btn'
                rel='noreferrer'
              >
                Download CV
              </a>
            </div>
            <Stats />
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
}
