import React from 'react';

const CategoryButton = ({ name, category, setCategory }) => {
  return (
    <button
      className={`portfolio__filter-btn ${category === name ? 'active' : ''}`}
      onClick={() => setCategory(name)}
    >
      <span style={{textTransform : 'capitalize'}}>{name}</span>
    </button>
  );
};

export default CategoryButton;
