import './index.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { testimonials } from 'utils/@fake_db';
import { Title } from 'components';
import { TestimonialCard } from './components';

export default function Testimonials() {
  return (
    <section className='testimonials' id='testimonials'>
      <Title title='Clients & Reviews' />
      <div className='testimonials__container'>
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          grabCursor={true}
          initialSlide={1}
          centeredSlides={true}
          pagination={{
            clickable: true
          }}
        >
          {testimonials.map(testimonial => (
            <SwiperSlide key={testimonial.id}>
              <TestimonialCard testimonial={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
