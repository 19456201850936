import React from 'react';

const TimeLine = ({ data, icon }) => {
  return (
    <div className='resume-timeline'>
      {data.map(item => (
        <div key={item.id} className='resume-timeline__item'>
          <div className='absolute-icon'>
            <i className={`icon-${icon}`}></i>
          </div>
          <div className='container'>
            <div className='resume-timeline__item__date'>{item.date}</div>
            <div className='resume-timeline__item__title'>{item.title}</div>
            <div className='resume-timeline__item__company'>{item.company}</div>
            <div className='resume-timeline__item__description'>
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeLine;
