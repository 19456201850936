import { Title } from 'components';
import { pricingPlans } from 'utils/@fake_db';
import { PlanCard } from './components';
import './index.scss';

export default function Pricing() {
  return (
    <section id='pricing' className='pricing'>
      <Title title='Pricing Plans' />
      <div className='pricing__container'>
        {pricingPlans.map(plan => (
          <PlanCard plan={plan} key={plan.id} />
        ))}
      </div>
    </section>
  );
}
