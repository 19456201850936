import {
  AVATAR_ITEM_1,
  AVATAR_ITEM_3,
  SERVICE_ITEM_1,
  SERVICE_ITEM_2,
  SERVICE_ITEM_3,
  BLOG_ITEM_1,
  BLOG_ITEM_2,
  BLOG_ITEM_3,
  WORK_ITEM_1,
  WORK_ITEM_2,
  WORK_ITEM_3,
  WORK_ITEM_4,
  WORK_ITEM_5,
  WORK_ITEM_6,
  PRICE_ITEM_1,
  PRICE_ITEM_2,
  PRICE_ITEM_3
} from 'assets';

import SimpleLineIcon from 'react-simple-line-icons';

export const socials = [
  {
    id: 1,
    icon: <SimpleLineIcon name='social-linkedin' />,
    link: 'https://www.linkedin.com/in/harundogdu/',
    name: 'LinkedIn'
  },
  {
    id: 2,
    icon: <SimpleLineIcon name='social-youtube' />,
    link: 'https://www.youtube.com/channel/UC7tSevlHdPwzsMUr-x9IEfg',
    name: 'YouTube'
  },
  {
    id: 3,
    icon: <SimpleLineIcon name='social-github' />,
    link: 'https://www.github.com/harundogdu',
    name: 'GitHub'
  },
  {
    id: 4,
    icon: <SimpleLineIcon name='social-twitter' />,
    link: 'https://www.twitter.com/HarunnDogdu',
    name: 'Twitter'
  },
  {
    id: 6,
    icon: <SimpleLineIcon name='envelope' />,
    link: 'mailto:harundogdu06@gmail.com',
    name: 'Email'
  }
];

export const services = [
  {
    id: 1,
    icon: SERVICE_ITEM_1,
    title: 'Web Design',
    content:
      'I design and develop services for customers of all sizes, specializing in creating stylish, modern websites, web services and online stores.',
    bgColor: '#FF9494',
    color: '#141414'
  },
  {
    id: 2,
    icon: SERVICE_ITEM_2,
    title: 'Web Development',
    content:
      'I develop websites and web applications that are fast, easy to use, and built with best practices.',
    bgColor: '#D0B8A8',
    color: '#141414'
  },
  {
    id: 3,
    icon: SERVICE_ITEM_3,
    title: 'Mobile App',
    content:
      'I develop mobile applications that are fast, easy to use, and built with best practices.',
    bgColor: '#87A2FB',
    color: '#141414'
  }
];

export const footerItems = [
  {
    id: 1,
    icon: <SimpleLineIcon name='fire' />,
    title: '200+',
    content: 'Project Completed'
  },
  {
    id: 2,
    icon: <SimpleLineIcon name='cup' />,
    title: '5000+',
    content: 'Cup of coffee'
  },
  {
    id: 3,
    icon: <SimpleLineIcon name='people' />,
    title: '50+',
    content: 'Happy Clients'
  },
  {
    id: 4,
    icon: <SimpleLineIcon name='clock' />,
    title: '1000+',
    content: 'Working Hours'
  }
];

export const stats = [
  { id: 1, level: '90%', title: 'Development', color: '#f1c40f' },
  { id: 2, level: '80%', title: 'UI/UX Design', color: '#e67e22' },
  { id: 3, level: '70%', title: 'Marketing', color: '#faafaf' }
];

export const posts = [
  {
    id: 1,
    title: 'Remix.js | Zero to Hero - Part-1',
    category: 'Development',
    description:
      'Remix is a new framework for building web applications. It’s built on top of React, React Router, and React Query.',
    link: 'https://medium.com/@harundogdu/remix-js-zero-to-hero-part-1-846ee7b52444',
    image: BLOG_ITEM_1,
    date: '17 June 2022',
    author: 'Harun Doğdu'
  },
  {
    id: 2,
    title: 'Git and GitHub 101',
    category: 'Business',
    link: 'https://medium.com/@harundogdu/git-ve-github-101-9d323197c960',
    description:
      'Git is a version control system for tracking changes in computer files and coordinating work on those files among multiple people.',
    image: BLOG_ITEM_2,
    date: '14 June 2022',
    author: 'Harun Doğdu'
  },
  {
    id: 3,
    title: 'What is Framer Motion?',
    category: 'Tutorial',
    description:
      'Framer Motion is a production-ready motion library for React. It’s designed to make adding motion to UIs as simple as possible.',
    link: 'https://medium.com/@harundogdu/react-jsde-animasyon-i%C5%9Flemleri-i%C3%A7in-framer-motion-kullan%C4%B1m%C4%B1-601b93faa374',
    image: BLOG_ITEM_3,
    date: '10 April 2022',
    author: 'Harun Doğdu'
  }
];

export const works = [
  {
    id: 1,
    title:
      'This project is the MERN Full Stack Blog application that I developed using Node Js, Mongodb, Express Js, Create React App, React Redux Toolkit.',
    category: 'React',
    link: 'https://blogapp-umber.vercel.app/',
    github: 'https://github.com/harundogdu/blogapp',
    image: WORK_ITEM_1
  },
  {
    id: 2,
    title:
      'With Laravel 8.* ,The construction of an advanced quiz website application that I have developed.',
    category: 'Laravel',
    link: 'https://www.quizzers.harundogdu.com/',
    github: 'https://github.com/harundogdu/quiz',
    image: WORK_ITEM_2
  },
  {
    id: 3,
    title:
      'This project is a Remix.run movie site application that I developed using Remix Run, Tailwind CSS and TMDB.',
    category: 'Remix Run',
    link: 'https://movie-app-with-remix.vercel.app/',
    github: 'https://github.com/harundogdu/movie-app-with-remix',
    image: WORK_ITEM_3
  },
  {
    id: 4,
    title:
      'These projects are my Node.js projects that I developed using React.js.',
    category: 'React',
    link: '#',
    github: 'https://github.com/harundogdu/react-projects',
    image: WORK_ITEM_4
  },
  {
    id: 5,
    title:
      'These projects are my Node.js projects that I developed using NodeJS.',
    category: 'NodeJS',
    link: '#',
    github: 'https://github.com/harundogdu/nodejs_projects',
    image: WORK_ITEM_5
  },
  {
    id: 6,
    title: 'This repo contains examples I developed with Typescript.',
    category: 'NodeJS',
    link: '#',
    github: 'https://github.com/harundogdu/typescript-bootcamp',
    image: WORK_ITEM_6
  }
];

export const pricingPlans = [
  {
    id: 1,
    active: false,
    name: 'Basic',
    price: 300,
    description:
      'Your basic level requests are successfully completed. You can use this plan for your personal projects.',
    image: PRICE_ITEM_1,
    features: ['1GB Disk Space', '10GB Bandwidth', '5 Email Accounts']
  },
  {
    id: 2,
    name: 'Premium',
    active: true,
    price: 500,
    description:
      'Your premium level requests are successfully completed. You can use this plan for your personal projects.',
    image: PRICE_ITEM_2,
    features: ['2GB Disk Space', '20GB Bandwidth', '10 Email Accounts']
  },
  {
    id: 3,
    name: 'Ultimate',
    active: false,
    price: 800,
    description:
      'Your ultimate level requests are successfully completed. You can use this plan for your personal projects.',
    image: PRICE_ITEM_3,
    features: ['3GB Disk Space', '30GB Bandwidth', '15 Email Accounts']
  }
];

export const navItems = [
  { id: 1, title: '#home', icon: <SimpleLineIcon name='home' /> },
  { id: 2, title: '#about', icon: <SimpleLineIcon name='user-following' /> },
  { id: 3, title: '#services', icon: <SimpleLineIcon name='briefcase' /> },
  { id: 4, title: '#resume', icon: <SimpleLineIcon name='graduation' /> },
  { id: 5, title: '#portfolio', icon: <SimpleLineIcon name='layers' /> },
  { id: 6, title: '#blog', icon: <SimpleLineIcon name='note' /> },
  { id: 7, title: '#contact', icon: <SimpleLineIcon name='bubble' /> }
];

export const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    title: 'CEO of ABC',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    image: AVATAR_ITEM_1
  },
  {
    id: 2,
    name: 'Michael Doe',
    title: 'CEO of XYZ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    image: AVATAR_ITEM_3
  }
];

export const experience = [
  {
    id: 1,
    title: 'Software Engineer',
    company: 'Fimple',
    content:
      'I work as a software engineer at a company called Fimple, which offers financial software.',
    date: '2022 October - Present'
  },
  {
    id: 2,
    title: 'Full Stack Web Intern Developer',
    company: 'Jotform',
    content:
      'Here; I have experienced web development with React and web service writing with PHP and NodeJS.',
    date: '2022 July - 2022 September'
  },
  {
    id: 3,
    title: 'React / React Native Intern Developer',
    company: 'Cubicl',
    content:
      'Here; I have experienced web development with React, mobile development with React Native, and service writing with PHP & Laravel.',
    date: '2021 - 2022'
  },
  {
    id: 4,
    title: 'PHP & Laravel Backend Intern Developer',
    company: 'Epigra',
    content:
      'Here; I have improved my PHP, Laravel and MySQL knowledge. I learned about design patterns and architectural patterns.',
    date: '2021'
  }
];

export const education = [
  {
    id: 1,
    title: 'Software Engineering',
    company: 'Karadeniz Technical University',
    content:
      'I am graduating from the Software Engineering department of Karadeniz Technical University.',
    date: '2017 - 2022'
  },
  {
    id: 2,
    title: 'Web Design and Programming',
    company: 'Altındağ IMKB Technical Anatolian High School',
    content:
      'I graduated from the Web Design and Programming department of Altındağ IMKB Technical Anatolian High School.',
    date: '2012 - 2016'
  }
];
