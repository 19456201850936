import React from 'react';
import { socials } from 'utils/@fake_db';

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      {socials.map(social => (
        <a
          key={social.id}
          href={social.link}
          className='header__social'
          target='_blank'
          rel='noopener noreferrer'
          aria-label={social.name}
        >
          {social.icon}
        </a>
      ))}
    </div>
  );
};

export default HeaderSocials;
