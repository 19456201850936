import './index.scss';
import React from 'react';
import { navItems } from 'utils/@fake_db';

export default function Sidebar() {
  const { hash } = window.location;
  const [active, setActive] = React.useState(hash || '#home');

  const handleNavItemClick = title => {
    setActive(title);
    document.querySelector('.aside').classList.remove('open');
  };
  return (
    <aside className='aside'>
      <a href='#home' className='nav__logo' aria-label='Logo'>
        <div className='nav__container'>
          <span
            style={{
              fontWeight: '800',
              color: 'var(--title-color)',
              fontSize: '30px'
            }}
          >
            H
          </span>
          <span
            style={{
              fontWeight: '800',
              color: 'var(--text-color)',
              fontSize: '30px',
              marginLeft: '-5px'
            }}
          >
            D
          </span>
        </div>
      </a>

      <nav className='nav'>
        <ul className='nav-list'>
          {navItems.map(item => (
            <li
              className={`nav-item ${active === item.title ? 'active' : ''}`}
              key={item.id}
            >
              <a
                href={item.title}
                className='nav-link'
                onClick={e => handleNavItemClick(item.title)}
                title={item.title}
                aria-label={item.title}
              >
                {item.icon}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className='nav__footer'>
        <span className='copyright'>&copy; 2022 - 2023.</span>
      </div>
    </aside>
  );
}
