import React from 'react';
import {
  About,
  Blog,
  Contact,
  Home,
  Portfolio,
  Pricing,
  Resume,
  Services,
  Sidebar,
  Testimonials
} from './containers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const handleHamburgerMenuClick = () => {
    const aside = document.querySelector('.aside');
    aside.classList.toggle('open');
  };

  return (
    <>
      <Sidebar />
      <main className='main'>
        <i className='icon-menu' onClick={handleHamburgerMenuClick}></i>
        <Home />
        <About />
        <Services />
        <Resume />
        <Portfolio />
        <Pricing />
        <Testimonials />
        <Blog />
        <Contact />
      </main>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
}
