import './index.scss';
import { education, experience } from 'utils/@fake_db';
import { Title } from 'components';
import { TimeLine } from './components';

export default function Resume() {
  return (
    <section className='resume' id='resume'>
      <Title title='Experience' />
      <div className='resume__content'>
        <TimeLine data={experience} icon='briefcase' />
        <TimeLine data={education} icon='graduation' />
      </div>
    </section>
  );
}
