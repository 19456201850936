import './index.scss';
import { Title } from 'components';
import { Form } from './components';

export default function Contact() {
  return (
    <section id='contact' className='contact'>
      <Title title='Get in Touch' />
      <div className='contact__content'>
        <div className='contact__content__info'>
          <h4>Let's talk about everything!</h4>
          <p>
            I am currently looking for new opportunities. My inbox is always
            open. Whether you have a question or just want to say hi, I'll try
            my best to get back to you!👋
          </p>
        </div>
        <Form />
      </div>
    </section>
  );
}
